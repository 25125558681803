@tailwind base;
@tailwind components;
@tailwind utilities;

.property-img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.service-img {
    background-image : url("../src/assets/images/african-american-worker-standing-uniform-wearing-safety-hat-factory.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* opacity */
}

.sofa-img {
    background-image : url("../src/assets/images/sofa.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* opacity */
}

.carpet-img {
    background-image : url("../src/assets/images/carpet4.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* opacity */
}